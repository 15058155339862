/* eslint-disable import/no-named-as-default */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Redirect, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUserAddress } from "eth-hooks";
import TournamentHeader from "../components/v2/Tournament/Header/TournamentHeader";
import Loader from "../components/v2/Loader";
import Rules from "../components/v2/Tournament/Rules";
import LeaderBoard from "../components/v2/Tournament/LeaderBoard/LeaderBoard";
import useTournamentData from "../hooks/TournamentData";
import { checkUserInPoolLeaderboard, formatConvertedValues } from "../helpers/utils";
import { useWallet } from "../providers/WalletProvider";
import { useWindowSize } from "../hooks/useWindowSize";
import MobileSocialNav from "../components/v2/common/MobileSocialNav";
import {
  BackArrow,
  ClockPending,
  CloseIcon,
  CoinIcon,
  FormatGold,
  PodiumNew,
  TeamGold,
  TropheyGold,
  Wallet,
  WalletGold,
  WinnerFirstIcon,
  WinnerFirstIconMobile,
  WinnerSecondIcon,
  WinnerSecondIconMobile,
  WinnerThirdIcon,
  WinnerThirdIconMobile,
} from "../assets/svg";
import PersonMoving from "../assets/gif/person-moving.gif";
import BracketsPage from "./Brackets/BracketsPage";
import BracketsPlayers from "./BracketsPlayers/BracketsPlayers";
import { Skeleton, Tooltip } from "antd";
import CountdownTimer from "../components/v2/Tournament/CountdownTimer";
import TournamentStatus from "../components/v2/TournamentStatus";
import { MultiStage } from "../components/v2/Tournament/MultiStageContainer";
import { GroupSelector } from "../components/v2/Tournament/GroupSelector/GroupSelector";
import callAxios from "../hooks/Axios";
import { PlayerBlock } from "../components/v2/Tournament/PlayerBlock/PlayerBlock";
import { GroupSection } from "../components/v2/Tournament/GroupsSection/GroupsSection";
import MultiStageBracketsSection from "./Brackets/MultstagePools/MultiStageBracketsSection";
import { StreamModal } from "../components/v2/Tournament/StreamModal";
import { useDebounce, useGetSecHeaders, usePriceConverter } from "../hooks";
import Flex from "../components/v2/common/Flex";
import ContactSection from "../components/v2/Tournament/ContactSection";
import useCallAxios from "../hooks/useAxiosCall";
import { ENDPOINT } from "../constants/endpoints";
import { useRecoilState } from "recoil";
import { selectedGameState } from "../recoil/gameState";
import { Button } from "../components/v2/common/Button";
import { WinnersComponent } from "../components/v2/Tournament/WinnersComponent";
import { getBracketStatusMessage, getLeaderboardStatusMessage } from "../constants/tournamentStatusMessages";

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SectionContainer = styled.div`
  margin-top: 57px;
  // margin-bottom: 200px;
  margin-left: 45px;
  margin-right: 67px;
  max-width: 1200px;

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 120px;
  }
  @media (min-width: 1570px) {
    margin: 107px auto 0;
  }
`;

const CollectingDataBanner = styled.div`
  height: 130px;
  background: #3f2faa;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;
`;

const CollectingDataBannerText = styled.div`
  font-size: 18px;
  color: #fff;
  margin-left: 23px;
  line-height: 24px;
`;

const NavButtonWrapper = styled(Flex)`
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  margin-bottom: 45px;
`;

const NavButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const NavButton = styled.button`
  background: #3f2faa;
  outline: none;
  border: none;
  min-width: 105px;
  width: fit-content;
  white-space: nowrap;
  height: 40px;
  color: #ffffff;
  padding: 8px;
  border-radius: 30px;
  background: ${props => (props.active ? "linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%)" : "#3f2faa")};
  cursor: ${props => (props.shouldDisable ? "default" : "pointer")};
  opacity: ${props => (props.shouldDisable ? 0.5 : 1)};
`;

const InfoContainer = styled.div`
  background: linear-gradient(270deg, #df4bbd 0%, #302862 100%);
  border-radius: 10px;
  display: flex;
  column-gap: 71px;
  align-items: center;
  height: 106px;
  margin-bottom: 32px;
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: 900px) {
    column-gap: 40px;
  }

  @media (max-width: 768px) {
    display: grid;
    background: #3a2f81;
    grid-template-columns: 1fr 1fr 1fr;
    height: fit-content;
    grid-gap: 20px;
    padding: 24px;
    row-gap: 25px;
  }
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  color: #fff;
  svg {
    margin-bottom: 13px;
    width: 23px;
    height: 23px;
  }
  div:nth-of-type(1) {
    font-size: 14px;
    font-weight: 500;
  }
  div:nth-of-type(2) {
    font-size: 12px;
    font-weight: 325;
  }

  @media (max-width: 968px) {
    div:nth-of-type(1) {
      font-size: 12px;
      margin-bottom: 4px;
      font-weight: 350;
    }

    div:nth-of-type(2) {
      font-size: 10px;
      font-weight: 100;
    }
  }
  @media (max-width: 768px) {
    svg {
      margin-bottom: 13px;
      width: 18px;
      height: 18px;
    }
    div:nth-of-type(1) {
      font-size: 12px;
      margin-bottom: 4px;
      font-weight: 350;
    }
    div:nth-of-type(2) {
      font-size: 10px;
      font-weight: 325;
    }
  }
`;
const TokenImg = styled.img`
  width: 11px;
  height: 9px;
  margin-left: 4px;
`;

const PlayerBlocksContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 13px;
`;

const StreamBtn = styled.button`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid #be7eff;
  background-image: url(${props => (props.bg ? props.bg : "https://placehold.co/75x75")});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const LiveIndicator = styled.div`
  position: absolute;
  bottom: -5px;
  left: -5px;
  background-color: #eb0400;
  border: 2px solid #201366;
  width: 38px;
  height: 20px;
  padding: 0;
  gap: 0px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding: 0;
  }
`;

const PlayerSearchInput = styled.input`
  border: 1px solid #eeeeee80;
  width: 300px;
  border-radius: 4px;
  height: 40px;
  background-color: transparent;
  color: #eeeeee;
  padding-left: 12px;
`;

const PlayerSearchContainer = styled.div`
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
`;

const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  display: ${props => (props.visible ? "block" : "none")};

  & > svg {
    background: none;
  }
`;

const FilterBtn = styled(Button)`
  background: ${props => (props.active ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)" : "#3F338C")};
  border-radius: 30px;
  height: 37px;
  flex-shrink: 0;
  padding: 0px 20px;
  font-size: 14px;
  text-transform: capitalize;
  &:disabled {
    opacity: ${props => (props.active ? 1 : 0.4 )};
  }
`;

const NoPlayersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
`;

const GroupBlockLoader = styled.div`
  width: 275px;
  height: 59px;
  border-radius: 10px;
  background: #3f2faa;
  padding: 10px;
`;

const PlayerBlockLoader = styled.div`
  width: calc(100% / 2 - 13px);
  height: 59px;
  border-radius: 10px;
  background: #3f2faa;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const RuleBlockLoader = styled.div`
  padding: 20px;
  border-radius: 10px;
  background: #3f2faa;
`;

// eslint-disable-next-line no-unused-vars
const TournamentDetails = ({ data, isError, userAddress, userId, fetchTournamentData }) => {
  const headers = useGetSecHeaders();
  const { callAxiosFunc } = useCallAxios();
  const [poolStarted, setPoolStarted] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [bracketsCounterData, setBracketsCounterData] = useState(null); // Data for timer banner in BEST_OF_X tournament modes
  const [leaderboardCounterData, setLeaderboardCounterData] = useState(null); // Data for timer banner in BEST_OF_X tournament modes
  const [multiStageCounterData, setMultiStageCounterData] = useState(null);
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedStageId, setSelectedStageId] = useState(
    data?.stages.find(stage => stage.isActive)?.stageId ?? data?.stages?.[0]?.stageId, // Using ?? instead of || in case stageId of active stage is 0 for some reason.
  );
  const [selectedStageIdx, setSelectedStageIdx] = useState(
    data?.stages.find(stage => stage.isActive) ? data?.stages.find(stage => stage.isActive)?.stageId - 1 : 0,
  );
  const [activeButton, setActiveButton] = useState(data?.stages?.[selectedStageIdx]?.optionBtns[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSectionData, setSelectionData] = useState([]);
  const [groupIdSelected, setGroupIdSelected] = useState(null);
  const [extraInfo, setExtraInfo] = useState(null);

  const [showStreamModal, setShowStreamModal] = useState(false);
  const toggleStreamModal = () => {
    setShowStreamModal(!showStreamModal);
  };

  const [streamData, setStreamData] = useState([]);
  const [previewStream, setPreviewStream] = useState(streamData[0]);

  const [selectedGame, setSelectedGame] = useRecoilState(selectedGameState);

  const getStageData = async (option, stageId, groupId, pageId, branchId) => {
    setIsLoading(true);
    setSelectionData([]);
    const url = `${ENDPOINT.OPTION_DATA}/${data?.id}/${option}?stageId=${stageId}${
      pageId && option === "bracket" ? `&pageId=${pageId}` : ""
    }${branchId && option === "bracket" ? `&branchId=${branchId}` : ""}${
      groupIdSelected > 0 && option !== "groups" ? `&groupId=${groupId}` : ""
    }`;

    try {
      const response = await callAxios({
        method: "GET",
        url: url,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });
      if (response?.data) {
        setSelectionData(response?.data?.data);
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  // Same as getStageData but returns the response instead of updating  state and takes in state variables for the first three arguments
  const returnCurrentStageData = async (pageId, branchId) => {
    const url = `${ENDPOINT.OPTION_DATA}/${data?.id}/${activeButton}?stageId=${selectedStageId}${
      pageId && activeButton === "bracket" ? `&pageId=${pageId}` : ""
    }${branchId && activeButton === "bracket" ? `&branchId=${branchId}` : ""}${
      groupIdSelected > 0 && activeButton !== "groups" ? `&groupId=${groupIdSelected}` : ""
    }`;

    try {
      const response = await callAxios({
        method: "GET",
        url: url,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });
      if (response?.data) {
        return response?.data?.data;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getExtraInfo = async (tournamentId, stageId) => {
    setIsLoading(true);
    try {
      const response = await callAxios({
        method: "GET",
        url: `${ENDPOINT.EXTRA_INFO}/${tournamentId}/${stageId}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });
      if (response?.data) {
        setExtraInfo(response?.data?.data);
        setGroupIdSelected(response?.data?.data?.groups[0]?.id);
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  const filterButton = item => {
    setIsLoading(true);
    setActiveButton(item);
    setSelectedSection(item);
    getStageData(item, selectedStageId, groupIdSelected);
  };

  const setStage = (activeButton, stageId, stageIndex) => {
    setSelectedStageId(stageId);
    setSelectedStageIdx(stageIndex);
    getExtraInfo(data?.id, stageId);
    getStageData(activeButton, stageId, groupIdSelected);
  };

  useEffect(() => {
    if (data && data.poolType === "MULTI_STAGE") {
      getStageData(activeButton, selectedStageId, groupIdSelected);
      getExtraInfo(data.id, selectedStageId);
      const currentStageIndex = selectedStageIdx || 0;
      const stageStatus = data?.stages?.[currentStageIndex]?.status;
      if (stageStatus === "FINISHED" && data?.winnersPrize?.length >= 1) {
        setActiveButton("winners");
      } else if (
        stageStatus === "JOINING" ||
        stageStatus === "PROCESSING_REGISTERED_USERS" ||
        stageStatus === "PENDING"
      ) {
        setActiveButton(data?.joinMode !== "TEAM" ? "players" : "teams");
      }
      // else {
      // setActiveButton("leaderboard");
      // }
    }

    if (data) {
      setSelectedGame(data.game);
    }
  }, [data, selectedStageIdx]);

  const checkUserInPool = (data, userId) => {
    const showButtonResult =
      (data.poolStatus === "NOT_STARTED" || data.poolStatus === "RUNNING") &&
      !checkUserInPoolLeaderboard(userId, data.leaderboard);
    setShowButton(showButtonResult);
  };
  const { isMobile } = useWindowSize();

  const [sectionsMap, setSectionsMap] = useState(["Brackets", "Players", "Info", "Contact Info"]);

  const { convertedPrice: convertedEntranceFee, conversionError: conversionEntranceError } = usePriceConverter(
    data?.entranceTokenSymbol || data?.tokenSymbol,
    data?.entrance || 0,
    "USD",
  );
  const { convertedPrice: convertedPrizePool, conversionError: conversionPrizePoolError } = usePriceConverter(
    data?.tokenSymbol,
    data?.poolPrize || 0,
    "USD",
  );

  const getStreams = async ({ poolId }) => {
    const response = await callAxiosFunc({
      method: "GET",
      url: `${ENDPOINT.GET_STREAM}?poolsId=${poolId}`,
      headers: JSON.stringify({ accept: "*/*" }),
    });
    if (response.err) {
      console.log(response.err);
    } else {
      // setStreamData(response?.data?.data);
      // setPreviewStream(response?.data?.data[0]);
      let temp = response?.data?.data?.filter(item => item?.isStreaming);
      if (temp.length > 0) {
        setStreamData(temp);
        setPreviewStream(temp[0]);
      }
    }
  };

  useEffect(() => {
    if (data && data.startDate) setPoolStarted(moment(Date.now()).isAfter(data.startDate));
    if (data && data.poolStatus && userId) checkUserInPool(data, userId);

    if (data) getStreams({ poolId: data?.id });

    if (data && (data.poolStatus === "FINISHED" || data.poolStatus === "ARCHIVED") && data.winnersPrize?.length >= 1) {
      // Only add Winners button if it's not already part of sectionsMap
      if (!sectionsMap.includes("Winners")) {
        const newSectionsMap = [...sectionsMap]; // Create a copy of the original array
        const playersIndex = newSectionsMap.indexOf("Players");

        // Insert "Winners" after "Players"
        newSectionsMap.splice(playersIndex + 1, 0, "Winners");

        // Update state with the modified array
        setSectionsMap(newSectionsMap);
      }

      // Update the selected section
      setSelectedSection("Winners");
    } else if (
      data &&
      (data.poolStatus === "RUNNING" ||
        data.poolStatus === "BRACKETS_CREATED" ||
        data.poolStatus === "FINISHED_COLLECTING_DATA")
    ) {
      setSelectedSection("Brackets");
    } else if (
      data &&
      data?.leaderboard?.length >= 1 &&
      (data.poolStatus === "NOT_STARTED" ||
        data.poolStatus === "JOINING" ||
        data.poolStatus === "PROCESSING_REGISTERED_USERS")
    ) {
      setSelectedSection("Players");
    } else {
      setSelectedSection("Info");
    }

    // Setting countdown timer data for brackets mode
    if (data) {
      const hasRegistrationStartDate = !!data.registrationStartDate;
      const hasRegistrationEndDate = !!data.registrationEndDate;
      if (data?.poolMode == "LEADERBOARD" || data?.poolMode == "ROUND_ROBIN") {
        if (data.poolStatus === "NOT_STARTED" || data?.poolStatus?.toUpperCase()?.includes("PENDING")) {
          setLeaderboardCounterData({
            endDate: hasRegistrationStartDate ? data.registrationStartDate : data.startDate,
            text: hasRegistrationStartDate ? "Registration opens in" : "Starting in",
          });
        } else if (data.poolStatus === "JOINING") {
          setLeaderboardCounterData({
            endDate: hasRegistrationEndDate ? data.registrationEndDate : data.endDate,
            text: hasRegistrationEndDate ? "Registration closes in" : "Ending in",
          });
        } else if (data.poolStatus === "PROCESSING_REGISTERED_USERS") {
          setLeaderboardCounterData({
            endDate: data.startDate,
            text: "Starting in",
          });
        } else if (data.poolStatus === "RUNNING") {
          setLeaderboardCounterData({
            endDate: data.endDate,
            text: "Ending in",
          });
        } else {
          setLeaderboardCounterData({
            text: "ㅤ",
          });
        }
      } else if (data?.poolMode == "MULTI_STAGE") {
        if (data?.poolStatus === "NOT_STARTED" || data?.poolStatus?.toUpperCase()?.includes("PENDING")) {
          setMultiStageCounterData({
            endDate: hasRegistrationStartDate ? data.registrationStartDate : data.startDate,
            text: hasRegistrationStartDate ? "Registration opens in" : "Starting in",
          });
        } else if (data.poolStatus === "JOINING") {
          setMultiStageCounterData({
            endDate: hasRegistrationEndDate ? data.registrationEndDate : data.endDate,
            text: hasRegistrationEndDate ? "Registration closes in" : "Ending in",
          });
        } else if (data.poolStatus === "PROCESSING_REGISTERED_USERS") {
          setMultiStageCounterData({
            endDate: data.startDate,
            text: "Starting in",
          });
        } else if (data.poolStatus === "RUNNING") {
          setMultiStageCounterData({
            endDate: data.endDate,
            text: "Ending in",
          });
        } else {
          setMultiStageCounterData({
            text: "ㅤ",
          });
        }
      } else {
        if (data.poolStatus === "NOT_STARTED" || data?.poolStatus?.toUpperCase()?.includes("PENDING")) {
          setBracketsCounterData({
            endDate: data.registrationStartDate,
            text: "Registration opens in",
          });
        } else if (data.poolStatus === "JOINING") {
          setBracketsCounterData({
            endDate: data.registrationEndDate,
            text: "Registration closes in",
          });
        } else if (data.poolStatus === "PROCESSING_REGISTERED_USERS") {
          setBracketsCounterData({
            endDate: data.startDate,
            text: "Tournament starts in",
          });
        } else if (data.poolStatus === "RUNNING") {
          setBracketsCounterData({
            endDate: data.endDate,
            text: "Tournament ending in",
          });
        } else {
          setBracketsCounterData({
            text: "ㅤ",
          });
        }
      }
    }
  }, [data, userId]);

  // Data for Bracket Steps
  const BracketsPoolStatus = ["JOINING", "PROCESSING_REGISTERED_USERS", "RUNNING", "FINISHED", "ARCHIVED"];
  const LeaderboardPoolStatus = ["NOT_STARTED", "RUNNING", "FINISHED", "ARCHIVED"];
  const bracketStatusMessage = getBracketStatusMessage(data);
  const leaderboardStatusMessage = getLeaderboardStatusMessage(data);

  const ratingTextColor = ["#F5BC46", "#66CC9B", "#6791FB"];
  const ratingIcons = [
    <WinnerFirstIcon id="firstplace" />,
    <WinnerSecondIcon id="secondplace" />,
    <WinnerThirdIcon id="thirdplace" />,
  ];
  const ratingIconsMobile = [
    <WinnerFirstIconMobile id="firstplace" />,
    <WinnerSecondIconMobile id="secondplace" />,
    <WinnerThirdIconMobile id="thirdplace" />,
  ];

  /**
   * The function `getLeaderboardData` takes in `leaderboardData` and `gameId` as parameters, and returns
   * an array of objects with modified properties.
   */
  const getLeaderboardData = (leaderboardData, gameId) =>
    leaderboardData.map((data, index) => ({
      item: { ...data, gameId, order: index + 1 },
      color: ratingTextColor[index],
      icon: isMobile ? ratingIconsMobile[index] : ratingIcons[index],
    }));

  const [playerSearchTerm, setPlayerSearchTerm] = useState("");

  const handleClear = () => {
    setPlayerSearchTerm("");
  };

  const debouncedPlayerSearchTerm = useDebounce(playerSearchTerm, 200);
  return isError ? (
    <LoaderWrapper>
      <Redirect to="/404" />
    </LoaderWrapper>
  ) : (
    data && (
      <>
        <TournamentHeader
          tournamentData={{
            ...data,
            isRunning: data.poolStatus === "RUNNING",
            isFinished:
              data.poolStatus === "FINISHED_COLLECTING_DATA" ||
              data.poolStatus === "FINISHED" ||
              data.poolStatus === "ARCHIVED",
            image: data.assets.poolDetailHeaderFront,
            bgImage: data.assets.poolDetailHeaderBack,
            currency: data.poolPrize,
          }}
          poolStarted={poolStarted}
          showButton={showButton}
          fetchTournamentData={fetchTournamentData}
          renderCountDownTimer={() => {
            return data?.poolMode == "LEADERBOARD" || data?.poolMode === "ROUND_ROBIN" ? (
              <div>
                <CountdownTimer {...leaderboardCounterData} isMobile={isMobile} />
              </div>
            ) : data?.poolMode === "MULTI_STAGE" ? (
              <div>
                <CountdownTimer {...multiStageCounterData} isMobile={isMobile} />
              </div>
            ) : (
              <div>
                <CountdownTimer {...bracketsCounterData} isMobile={isMobile} />
              </div>
            );
          }} // using the render props pattern here
        />

        {/* poolPrize, entrance, Team mode?, Format?, Type */}
        {/* //! InfoContainer */}
        {data.poolType !== "MULTI_STAGE" ? (
          <InfoContainer>
            <InfoColumn>
              <CoinIcon />
              <div>Prize Pool</div>
              <div>
                {data?.poolPrize ?? "-"}
                {data?.tokenImage ? <TokenImg src={data?.tokenImage} /> : data?.tokenSymbol}
                {!conversionPrizePoolError ? `${formatConvertedValues(convertedPrizePool)}` : null}
              </div>
            </InfoColumn>
            <InfoColumn>
              <WalletGold />
              <div>Entry Fee</div>
              <div>
                {data?.entrance ?? "-"}
                {data?.entranceTokenImage ? <TokenImg src={data?.entranceTokenImage} /> : null}
                {!conversionEntranceError ? `${formatConvertedValues(convertedEntranceFee)}` : null}
              </div>
            </InfoColumn>
            <InfoColumn>
              <TeamGold />
              <div>Mode</div>
              <div>{data.joinMode === "TEAM" ? `Teams of ${data?.gameDetails?.teamUsersQty}` : "Solo"}</div>
            </InfoColumn>
            <InfoColumn>
              <FormatGold />
              <div>Format</div>
              <div>{data?.secBannerInfo?.format || "-"}</div>
            </InfoColumn>
            <InfoColumn>
              <TropheyGold width="26px" height="26px" />
              <div>Type</div>
              <div>{data?.secBannerInfo?.type || "-"}</div>
            </InfoColumn>
          </InfoContainer>
        ) : null}

        {data?.poolMode == "LEADERBOARD" || data?.poolMode === "ROUND_ROBIN" ? (
          <>
            <TournamentStatus
              isMobile={isMobile}
              isPaid={data.isPaid}
              status={data.poolStatus}
              message={leaderboardStatusMessage}
              steps={LeaderboardPoolStatus}
            />
          </>
        ) : (
          <>
            <TournamentStatus
              isMobile={isMobile}
              isPaid={data.isPaid}
              status={data.poolStatus}
              message={bracketStatusMessage}
              steps={BracketsPoolStatus}
            />
          </>
        )}

        {data.poolType === "MULTI_STAGE" ? (
          <MultiStage
            data={data?.stages}
            tournamentData={data}
            setActiveButton={setActiveButton}
            setStageId={setStage}
            extraInfo={extraInfo}
            joinMode={data?.joinMode}
          />
        ) : null}

        {data.poolStatus === "FINISHED_COLLECTING_DATA" && (
          <CollectingDataBanner>
            <ClockPending />
            <CollectingDataBannerText>
              The tournament is over, we&apos;re processing the results and prizes
            </CollectingDataBannerText>
          </CollectingDataBanner>
        )}
        {data.poolMode === "LEADERBOARD" || data.poolMode === "ROUND_ROBIN" ? (
          <>
            <LeaderBoard
              isFinished={data.poolStatus === "FINISHED" || data.poolStatus === "ARCHIVED"}
              isFinishedCollectingData={data.poolStatus === "FINISHED_COLLECTING_DATA"}
              leaderBoardData={getLeaderboardData(data?.leaderboard, data?.game)}
              // isMobile={isMobile}
              gamePlatform={data.platform}
              koinsLeaderboardHeaders={data?.displayData?.sort((a, b) => a?.dataOrder - b?.dataOrder)}
              dynamicHeaders={data?.displayData?.sort((a, b) => a?.dataOrder - b?.dataOrder)}
              gameId={data.game}
              joinMode={data.joinMode}
              winnersPrize={data?.winnersPrize}
              template={data.detailsTemplate}
              winnerSectionBg={data.assets?.winnersBackground}
              status={data.poolStatus}
              organizerInfo={data.organizerInfo}
              poolId={data.id}
              isLoading={isLoading}
            />
            <Rules rules={data.rules} />
          </>
        ) : data.poolMode === "MULTI_STAGE" ? (
          <div>
            <NavButtonWrapper justifyContent="space-between" columnGap="20">
              <NavButtonContainer>
                {[
                  ...data?.stages?.[selectedStageIdx]?.optionBtns?.filter(item => {
                    // Hiding Groups button stageType not LEADERBOARD OR if it is LEADERBOARD but playersPerGroup is 0
                    if (
                      item === "groups" &&
                      (data?.stages?.[selectedStageIdx]?.stageType !== "LEADERBOARD" ||
                        data?.stages?.[selectedStageIdx]?.extraConfigData?.leaderboardConfig?.playersPerGroup <= 0)
                    )
                      return false;

                    return true;
                  }),
                  "Contact Info",
                ].map(item => (
                  <Tooltip
                    title={
                      item == "Contact Info" && (!data?.organizerInfo || data?.organizerInfo?.length < 1)
                        ? `Organizer info not available`
                        : ""
                    }
                    color="#7265C8"
                    overlayStyle={{
                      maxWidth: "fit-content",
                      fontSize: "11px",
                      padding: "11px 8px",
                      borderRadius: "6px",
                    }}
                  >
                    {/* Adding an extra div since tooltip won't work on a disabled button */}
                    <div>
                      <FilterBtn
                        key={item}
                        active={activeButton === item}
                        onClick={() => filterButton(item)}
                        disabled={
                          isLoading ||
                          (item === "Contact Info" && (!data?.organizerInfo || !data.organizerInfo?.length)) ||
                          item === activeButton
                        }
                      >
                        {item}
                      </FilterBtn>
                    </div>
                  </Tooltip>
                ))}
              </NavButtonContainer>
            </NavButtonWrapper>
            {extraInfo?.groupsQty > 1 && activeButton === "leaderboard" ? (
              <GroupSelector
                options={extraInfo?.groups}
                title="Groups"
                onChange={setGroupIdSelected}
                onChangeValue={groupIdSelected}
                getStageData={getStageData}
                activeButton={activeButton}
                activeStage={selectedStageId}
              />
            ) : null}
            <div style={{ position: "relative" }}>
              {activeButton === "leaderboard" && (
                <LeaderBoard
                  isFinished={data.poolStatus === "FINISHED" || data.poolStatus === "ARCHIVED"}
                  isFinishedCollectingData={data.poolStatus === "FINISHED_COLLECTING_DATA"}
                  leaderBoardData={
                    data.poolType === "MULTI_STAGE"
                      ? getLeaderboardData(selectedSectionData, data?.game)
                      : getLeaderboardData(data?.leaderboard, data?.game)
                  }
                  // isMobile={isMobile}
                  gamePlatform={data.platform}
                  koinsLeaderboardHeaders={data?.displayData?.sort((a, b) => a?.dataOrder - b?.dataOrder)}
                  dynamicHeaders={data?.displayData?.sort((a, b) => a?.dataOrder - b?.dataOrder)}
                  gameId={data.game}
                  joinMode={data.joinMode}
                  winnersPrize={data?.winnersPrize}
                  template={data.detailsTemplate}
                  winnerSectionBg={data.assets?.winnersBackground}
                  status={data.poolStatus}
                  poolType={data.poolType}
                  poolId={data.id}
                  groupIdSelected={groupIdSelected}
                  isLoading={isLoading}
                />
              )}
              {/* Added checks to ensre data is ready and in the correct format before sending it to the MultiStage brackets section */}
              {activeButton === "bracket" &&
                typeof selectedSectionData === "object" &&
                !Array.isArray(selectedSectionData) && (
                  <MultiStageBracketsSection
                    sectionData={selectedSectionData}
                    tournamentData={data}
                    tournamentId={data.id}
                    userPositionInfo={data?.stages?.[selectedStageIdx]?.userPositionInfo}
                    playersQty={data?.gameDetails?.tournamentPlayersQty}
                    returnCurrentStageData={returnCurrentStageData}
                    returnStageData
                    selectedStageIdx={selectedStageIdx}
                    branchPagesInfo={
                      data?.stages?.[selectedStageIdx]?.extraConfigData?.bracketsConfig?.branchPagesInfo || {}
                    }
                    stageId={selectedStageId}
                    poolMode={data?.poolMode}
                  />
                )}

              {activeButton === "groups" && (
                <>
                  {isLoading ? (
                    <GroupBlockLoader>
                      <Skeleton active paragraph={{ rows: 0 }} />
                    </GroupBlockLoader>
                  ) : (
                    <GroupSection
                      isLoading={isLoading}
                      selectedSectionData={selectedSectionData}
                      extraInfo={extraInfo}
                      setActiveButton={setActiveButton}
                      getStageData={getStageData}
                      stageId={selectedStageId}
                    />
                  )}
                </>
              )}

              {(activeButton === "players" || activeButton === "teams") && (
                <>
                  {isLoading ? (
                    <PlayerBlocksContainer>
                      <PlayerBlockLoader>
                        <Skeleton.Avatar active size={33}/>
                        <Skeleton active title={false} paragraph={{ rows: 1 }}/>
                      </PlayerBlockLoader>
                      <PlayerBlockLoader>
                        <Skeleton.Avatar active size={33}/>
                        <Skeleton active title={false} paragraph={{ rows: 1 }}/>
                      </PlayerBlockLoader>
                      <PlayerBlockLoader>
                        <Skeleton.Avatar active size={33}/>
                        <Skeleton active title={false} paragraph={{ rows: 1 }}/>
                      </PlayerBlockLoader>
                      <PlayerBlockLoader>
                        <Skeleton.Avatar active size={33}/>
                        <Skeleton active title={false} paragraph={{ rows: 1 }}/>
                      </PlayerBlockLoader>
                    </PlayerBlocksContainer>
                  ) : (
                    <>
                      <h3 style={{ color: "white", marginTop: "32px", marginBottom: "0px" }}>
                        {data?.joinMode === "TEAM" ? "Teams" : "Players"}
                      </h3>
                      <PlayerBlocksContainer>
                        {selectedSectionData?.length > 0 ? (
                          <>
                            {selectedSectionData?.map((player, index) => {
                              return (
                                <PlayerBlock
                                  nickname={player?.nick}
                                  accountName={player?.accountName}
                                  platformUserId={player?.platformUserId}
                                  playerId={player?.playerId}
                                  avatar={player?.avatarURL}
                                  ind={index}
                                  isOnWaitlist={false}
                                  isQualified={false}
                                  isLoading={isLoading}
                                  playerData={player?.playerData}
                                  joinMode={data?.joinMode}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <NoPlayersContainer>
                            <Flex direction="column" justifyContent="center" alignItems="center">
                              <img src={PersonMoving} alt="person moving the head" width={100} height={100} />
                              <PodiumNew style={{ width: "500px" }} />
                            </Flex>
                            <p style={{ fontSize: "24px", color: "white" }}>No players found</p>
                          </NoPlayersContainer>
                        )}
                      </PlayerBlocksContainer>
                    </>
                  )}
                </>
              )}
              {activeButton === "winners" && (
                <WinnersComponent
                  data={getLeaderboardData(data?.leaderboard, data?.game)}
                  isFinished={data.poolStatus === "FINISHED" || data.poolStatus === "ARCHIVED"}
                  winnersPrize={data?.winnersPrize}
                  winnerSectionBg={data?.assets?.winnersBackground}
                />
              )}
              {activeButton === "info" && (
                <>
                  {isLoading ? (
                    <RuleBlockLoader>
                      <Skeleton active paragraph={{ rows: 1 }} />
                    </RuleBlockLoader>
                  ) : (
                    <Rules rules={data?.stages?.[selectedStageIdx]?.rules} isLoading={isLoading} />
                  )}
                </>
              )}
              {activeButton === "Contact Info" && <ContactSection organizerInfo={data?.organizerInfo} />}
            </div>
          </div>
        ) : (
          <div>
            <NavButtonWrapper justifyContent="space-between" columnGap="20">
              <NavButtonContainer>
                {sectionsMap.map(item => (
                  <Tooltip
                    title={
                      item == "Brackets" && (data?.poolStatus === "NOT_STARTED" || data?.poolStatus === "JOINING")
                        ? `Brackets will be available once the tournament starts`
                        : item == "Winners" && (data?.poolStatus === "NOT_STARTED" || data?.poolStatus === "JOINING")
                        ? `Winners will be available once the tournament is finished`
                        : item == "Contact Info" && (!data?.organizerInfo || data?.organizerInfo?.length < 1)
                        ? `Organizer info not available`
                        : ""
                    }
                    color="#7265C8"
                    overlayStyle={{
                      maxWidth: "fit-content",
                      fontSize: "11px",
                      padding: "11px 8px",
                      borderRadius: "6px",
                    }}
                  >
                    <NavButton
                      onClick={() => {
                        if (
                          (item == "Brackets" || item == "Winners") &&
                          data?.poolStatus !== "RUNNING" &&
                          data.poolStatus !== "FINISHED" &&
                          data.poolStatus !== "FINISHED_COLLECTING_DATA" &&
                          data.poolStatus !== "ARCHIVED"
                        ) {
                          return;
                        }

                        if (item === "Contact Info" && (!data?.organizerInfo || data?.organizerInfo?.length < 1))
                          return;
                        setSelectedSection(item);
                      }}
                      active={selectedSection == item}
                      isMobile={isMobile}
                      // Cannot use disabled as disabled buttons do not show Tooltips. Using shouldDisable instead
                      shouldDisable={
                        item == "Contact Info" && (!data?.organizerInfo || data?.organizerInfo?.length < 1)
                      }
                    >
                      {item === "Players" && data?.joinMode === "TEAM" ? "Teams" : item}
                    </NavButton>
                  </Tooltip>
                ))}
              </NavButtonContainer>
              {selectedSection === "Players" && (
                <PlayerSearchContainer>
                  <PlayerSearchInput
                    onChange={e => setPlayerSearchTerm(e?.target?.value)}
                    placeholder="Search"
                    type="search"
                  />
                  <ClearButton onClick={handleClear} visible={!!playerSearchTerm}>
                    <CloseIcon />
                  </ClearButton>
                </PlayerSearchContainer>
              )}
            </NavButtonWrapper>
            <div style={{ position: "relative" }}>
              {selectedSection === "Brackets" && (
                <div style={{ minHeight: "50vh" }}>
                  <BracketsPage
                    tournamentData={data}
                    tournamentId={data.id}
                    userPositionInfo={data.userPositionInfo}
                    playersQty={data?.gameDetails?.tournamentPlayersQty}
                  />
                </div>
              )}
              {selectedSection === "Info" && <Rules rules={data.rules} />}
              {selectedSection === "Contact Info" && <ContactSection organizerInfo={data.organizerInfo} />}
              {selectedSection === "Players" && (
                <BracketsPlayers
                  joinMode={data.joinMode}
                  poolStatus={data.poolStatus}
                  players={data.leaderboard?.filter(player => {
                    if (!debouncedPlayerSearchTerm) return true;

                    return player?.nick?.toLowerCase()?.includes(debouncedPlayerSearchTerm?.toLowerCase()?.trim());
                  })}
                  poolId={data.id}
                />
              )}
              {/* Following is for the winners section in brackets tournament pages */}
              {selectedSection === "Winners" && (
                <WinnersComponent
                  data={getLeaderboardData(data?.leaderboard, data?.game)}
                  isFinished={data.poolStatus === "FINISHED" || data.poolStatus === "ARCHIVED"}
                  winnersPrize={data?.winnersPrize}
                  winnerSectionBg={data?.assets?.winnersBackground}
                />
              )}
            </div>
          </div>
        )}
        <MobileSocialNav />
        {!isMobile && streamData?.length >= 1 && (
          <div style={{ position: "absolute", right: "40px", bottom: "40px" }}>
            <StreamBtn onClick={toggleStreamModal} bg={previewStream?.mainThumbnailUrl}>
              <LiveIndicator>
                <span>LIVE</span>
              </LiveIndicator>
            </StreamBtn>
            <StreamModal showModal={showStreamModal} toggleModal={toggleStreamModal} channelList={streamData} />
          </div>
        )}
      </>
    )
  );
};
export default function TournamentPage() {
  const { id } = useParams();
  const { data, isLoading, isError, fetchTournamentData } = useTournamentData(id);
  const { injectedProvider } = useWallet();
  const address = useUserAddress(injectedProvider);
  const headers = useGetSecHeaders();
  return (
    <>
      <SectionContainer>
        {isLoading ? (
          <LoaderWrapper>
            <Loader animation="rotate" width={120} />
          </LoaderWrapper>
        ) : (
          <TournamentDetails
            data={data}
            fetchTournamentData={fetchTournamentData}
            isError={isError}
            userAddress={address}
            userId={headers.userId}
          />
        )}
      </SectionContainer>
    </>
  );
}
